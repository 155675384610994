.title {
    text-transform: uppercase;
    white-space: nowrap;
}

.subtitle {
    font-size: 0.8em;
    font-weight: 200;
    white-space: nowrap;
}
