@import "../../mixins";

.section {
    padding: 2rem;
    border-bottom: 2vh solid var(--colorA1);
    box-shadow: 0 0 0 1vh var(--colorA2);

    @include smallScreen {
        &:first-of-type {
            padding-top: 5rem;
        }
    }
}
