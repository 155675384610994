@import "../../../../mixins";

.carousel {
    width: 100% !important;
    height: 40% !important;
}

.frame {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.frameImage {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex: 1;
}

.frameText {
    font-weight: 600;
    font-size: 2em;
    text-align: center;
    background-color: var(--colorD1);

    @include textOutline(var(--colorD1));

    @include smallScreen {
        font-size: 1em;
    }
}
