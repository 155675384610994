@import "../../mixins";

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: 20px;
    height: 5em;
    transition: all 300ms;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;

    @include smallScreen {
        padding: 0.5em 1em;
    }
}

.subscribeButton {
    display: none;
    transform: scale(0.6);

    @include smallScreen {
        transform: scale(0.6);
        padding: 0.5em 1em;
    }
}

.topbarScrolled {
    background: white;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.048);
    font-size: 0.8rem;

    .subscribeButton {
        display: initial;
    }
}

.topbar,
.topbarScrolled {
    @include smallScreen {
        background: white;
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.048);
        font-size: 0.8rem;
    }
}

.logo {
    font-size: 2em;
}
