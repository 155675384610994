.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.frames {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 20px;
    left: 0;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 200ms;
}

.itemsNav {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    position: absolute;
    bottom: 0;
    left: 30%;
    right: 30%;
}

.navItem {
    height: 2px;
    background-color: var(--colorD2);
    flex: 1 0 auto;
}

.navItemActive {
    background-color: var(--colorA1);
}
