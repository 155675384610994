@import "../../../../mixins";

.heading {
    background-color: var(--colorA4);
    display: flex;
    align-items: center;

    @include smallScreen {
        flex-direction: column;
    }
}

.content {
    width: 200vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colorB1);

    @include smallScreen {
        width: 100vw;
        height: 70vh;
        padding: 0 20px;
        box-sizing: border-box;
    }
}

.pro {
    color: var(--colorA1);
}

.image {
    background-image: url(../../../../assets/images/architect.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 200vh;
    height: 100vh;

    @include smallScreen {
        width: 100vw;
        height: 50vh;
    }
}

.subscribeButton {
    @include smallScreen {
        padding: 2em 0;
    }
}
