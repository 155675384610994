$colorPrimary: var(--colorB1);
$colorSecondary: var(--colorA1);

.iconAndName {
    display: flex;
    align-items: center;
}

svg.icon {
    font-size: 1em;

    path {
        fill: var(--colorA1) !important;
    }
}

.name {
    font-weight: bold;
    margin-left: 0.3em;
    font-size: 1em;
    background: -webkit-linear-gradient(0deg, $colorPrimary, $colorSecondary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.description {
    font-size: 0.3em;
    text-align: left;
    color: var(--colorB1);
    text-transform: uppercase;
    padding-bottom: 0.3em;
}
