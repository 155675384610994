@import "../../mixins";

.button {
    font-size: 1.25rem;
    letter-spacing: 1px;
    font-weight: 600;
    border: none;
    padding: 0.8em 4em;
    border-radius: 1em;
    background-color: var(--colorC1);
    color: var(--colorD1);
    cursor: pointer;

    @include smallScreen {
        padding: 0.8em 1em;
    }

    &:hover {
        background-color: var(--colorA1);
    }
}
