@import "../../../../mixins";

.features {
    background-color: var(--colorB3);
    color: var(--colorB1);
}

.feature {
    padding: 0 20vw;
    margin-top: 50px;

    @include smallScreen {
        padding: 10px;
    }
}

.featureContent {
    display: flex;
    align-items: stretch;

    > :nth-child(1) {
        flex: 1 0 auto;
    }

    > :nth-child(2) {
        padding-left: 50px;
    }

    @include smallScreen {
        flex-direction: column;

        > :nth-child(1) {
            flex: 1 0 auto;
        }

        > :nth-child(2) {
            padding-left: 0;
        }
    }
}

.featureImageWrapper {
    display: flex;
    align-items: center;

    @include smallScreen {
        justify-content: center;
    }
}

.featureImage {
    width: 10vw;
    height: 10vw;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @include smallScreen {
        width: 60vw;
        height: 60vw;
    }
}
