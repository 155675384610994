@import "../../../../mixins";

.getStarted {
    background-color: var(--colorE1);
    color: var(--colorF1);
    font-size: 2rem;
    padding: 10vh 30vw;

    @include smallScreen {
        padding: 10vh 10vw;
    }
}

.buttonContainer {
    display: flex;
    justify-content: center;
}
