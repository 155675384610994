@mixin smallScreen {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin largeScreen {
    @media only screen and (min-width: 601px) {
        @content;
    }
}

@mixin textOutline($color) {
    text-shadow: -1px -1px 0 $color, 1px -1px 0 $color, -1px 1px 0 $color, 1px 1px 0 $color;
}
